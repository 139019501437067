"use strict";
import "./bootstrap";

import tippy, { followCursor } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

document.addEventListener("alpine:init", () => {
	// Magic: $tooltip
	Alpine.magic("tooltip", (el) => (message) => {
		let instance = tippy(el, { content: message, trigger: "manual" });
		instance.show();
		setTimeout(() => {
			instance.hide();
			setTimeout(() => instance.destroy(), 150);
		}, 2000);
	});

	// Directive: x-tooltip
	Alpine.directive("tooltip", (el, { expression, modifiers }) => {
		tippy(el, {
			content: expression,
			duration: [300, 400],
			delay: [50, 200],
			allowHTML: true,
			placement: modifiers[0] ?? "top",
			onShow(instance) {
				instance.setProps({
					theme: window.matchMedia("(prefers-color-scheme: dark)")
						.matches
						? "light"
						: "translucent",
				});
			},
			followCursor: "horizontal",
			plugins: [followCursor],
		});
	});
});
